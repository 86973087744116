import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@organisms/layout'
import Heading from '@particles/heading'
import Text from '@particles/text'
import SEO from '@atoms/seo'

const RequestPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Request a metric" location={location} />
    <Heading h1 margin="T4 B1.5">
      Request a metric
    </Heading>
    <Text size={1.25} style={{ lineHeight: '2.25rem' }}>
      Can&apos;t find what you&apos;re looking for?
    </Text>
    <Text size={1.25} margin="T1 B6">
      Request a metric and help build our MetricHQ library.
    </Text>
    <iframe
      frameBorder="0"
      height="850"
      marginHeight="0"
      marginWidth="0"
      src="https://docs.google.com/forms/d/e/1FAIpQLSd6jREQtujscmZsbWEL0hckIZX2ceZj6_cDjT0cSrErxIVYfw/viewform?embedded=true"
      width="100%"
    >
      Loading…
    </iframe>
  </Layout>
)

RequestPage.propTypes = {
  data: PropTypes.any,
  location: PropTypes.any
}

export default RequestPage
